import React from "react";
import FaqList from "modules/non-protected-page/faq-list";

export const useFaqList = () => {
  const faqLists = [
    {
      question: "What is Junia.ai?",
      answer:
        "Junia AI is the best AI writing tool for SEO. It allows users to generate original, high-quality, long-form, SEO-rich blog posts without sacrificing human touch. <br /><br/>Beyond writing, Junia AI offers in-depth SEO research and AI-generated visuals to ensure your content is optimized for search engines. With its intelligent features, this SEO AI writing tool streamlines the process of creating engaging SEO content, making it a comprehensive solution for maximizing your SEO efforts with the power of AI.",
    },
    {
      question: "How does Junia AI differ from other AI Writing tools?",
      answer:
        "Junia AI sets itself apart from other AI writing tools by providing a comprehensive solution specifically tailored to SEO and long-form content creation. It streamlines the entire process, from research to generation and optimization, making it an efficient tool for creating high-quality, SEO-friendly long-form content. <br /><br/> As an AI writing platform for SEO, Junia AI is uniquely designed to generate engaging, authentic, and SEO-optimized long-form content. Its unique AI Article Workflow facilitates the effortless creation of 3000 words+ articles, incorporating almost every aspect of SEO optimization. <br /><br/>Unlike other AI tools that only focuses on short-form content or chat-oriented applications, Junia AI is versatile and well-equipped to cater to the specific demands of long-form SEO content creation. This includes maintaining your brand voice and ensuring relevance to your target audience. <br /><br />What sets Junia AI further apart is its cost-effectiveness. While platforms like Surfer SEO charge $30 for a single article, Junia AI allows you to try its capabilities for free. This makes it an accessible choice for users seeking high-quality SEO content without the heavy upfront costs. <br /><br/>As Junia AI continues to evolve, it seeks to enhance its offerings even further. Future plans include integrating artificial intelligence into project management and team knowledge bases. By weaving AI into your existing workflow and collaboration processes, Junia AI aims to become an indispensable tool for boosting productivity and creativity in the realm of SEO-focused long-form content creation.",
    },
    {
      question: "How does Junia AI use my data?",
      answer: `Junia AI is committed to safeguarding your data and ensuring your privacy. We neither sell your data nor use it for training purposes. To learn more about Junia.ai's data security measures, visit <a href='/security' target='_blank'>our security policy page</a>. By prioritizing data protection, Junia.ai provides a secure environment where users can confidently create and manage their content without compromising their sensitive information. This commitment to security allows users to trust the platform and focus on producing high-quality content with peace of mind.`,
    },
  ];

  return faqLists;
};
const Faq = React.memo(() => {
  const faqLists = useFaqList();
  return <FaqList faqLists={faqLists} />;
});

export default Faq;
