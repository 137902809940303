import { Avatar } from "components/ui/avatar";
import { Box } from "components/ui/box";
import Image from "next/image";
import image1 from "public/images/etpuwj1.png";
import image3 from "public/images/YFTwuoXE_normal.jpeg";
import image4 from "public/images/fhnjQP5P_normal.jpeg";
import image7 from "public/images/o_zOxACq_normal.jpeg";
import image8 from "public/images/thu.png";

import React from "react";
import { ResponsiveText } from "modules/marketing-pages/front-page/shared";

const images = [image3, image4, image1, image7, image8];

const altTextByIndex = {
  0: "Hasan Toor: A Twitter AI influencer who uses Junia AI for content generation.",
  1: "Yi - Owner of multiple niche blogging sites, who uses Junia AI to write SEO-rich, long-form articles.",
  2: "Paula - A freelance content writer who uses Junia AI's writing tools to create blog posts.",
  3: "Ratan Chahar - A Twitter content creator who uses Junia AI for copywriting and blog writing.",
  4: "Thu Nghiem - A YouTuber who switched from another AI writer to Junia AI for writing tech-focused blog posts.",
};

export const WriterList = ({ showAltText }: { showAltText?: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {images.map((image, index) => {
        return (
          <Avatar
            key={index}
            sx={{
              width: "40px",
              height: "40px",
              marginLeft: index === 0 ? 0 : "-5px",
              border: "2px solid white",
            }}
          >
            <Image
              alt={showAltText ? altTextByIndex[index] : ""}
              src={image}
              width={40}
              height={40}
              style={{
                objectFit: "cover",
              }}
            />
          </Avatar>
        );
      })}
    </Box>
  );
};

export const ImageList = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "8px",
      }}
    >
      <WriterList />
      <ResponsiveText
        fontSize={10}
        sx={{
          color: "#485568",
          fontWeight: 600,
        }}
      >
        {"and thousands more"}
      </ResponsiveText>
    </Box>
  );
};
