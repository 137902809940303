import { serverRoutes } from "client-server-shared/config/routes";
import { BlackSoftContainer } from "components/container/soft";
import { Box } from "components/ui/box";
import { Button } from "components/ui/button";
import { Container } from "components/ui/container";
import { Typography } from "components/ui/Typography";
import Link from "next/link";
import React from "react";
import Stepper from "./stepper";
import { MainBackgroundWrapper } from "modules/non-protected-page/shared";
import { useStepData } from "./use-data";

const HowItWorks = ({ sx = {} }) => {
  const stepData = useStepData();
  return (
    <MainBackgroundWrapper
      component="section"
      sx={{
        borderBottomLeftRadius: "44px",
        borderBottomRightRadius: "44px",
        ...sx,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: "72px 0",
        }}
      >
        <Stepper stepData={stepData} />
        <Box
          sx={{
            maxWidth: "500px",
            textAlign: "center",
            margin: "auto",
            padding: "48px 12px",
          }}
        >
          <Typography marginBottom={24} variant="body1" color="#D4DCE5">
            {
              "Let AI help you take your long-form content creation to the next level, with less effort and more SEO results."
            }
          </Typography>
          <Button
            variant="outlined"
            component={Link}
            href={serverRoutes.register}
            capitalize
            sx={{
              color: "text.white",
              background: "#5B2D75",
              padding: "12px 24px",
              borderRadius: "22px",
              border: 0,
              fontSize: "16px",
              "&:hover": {
                background: "#5B2D75",
                border: 0,
                opacity: 0.8,
              },
            }}
          >
            {"Get More SEO Results with AI →"}
          </Button>
        </Box>
      </Container>
    </MainBackgroundWrapper>
  );
};

export default HowItWorks;
