import InfoBaseHighLight from "public/images/style-match/infobase-highlight.png";
import PrebuiltToolbar from "public/images/generator/prebuilt-toolbar.png";
import Video from "public/images/citation/video.png";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InteractiveCommand from "public/images/generator/interactive-command.png";
import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import askforRaisePic from "public/images/email/ask-for-raise.png";
import campaignPic from "public/images/marketing/campaign.png";
import generativeAiPic from "public/images/blog/generative-ai.png";
import launchProductPic from "public/images/social-media/launching-product.png";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";

import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";

import { withHtmlLink } from "utils/html-link";

import instagramBioImage1 from "public/images/social-media/blog-post-1.png";
import instagramBioImage2 from "public/images/social-media/blog-post-2.png";
import instagramBioImage3 from "public/images/social-media/blog-post-3.png";

export const useStepData = () => {
  return {
    steps: [
      {
        type: "image",
        stepNumber: "01",
        headline: "Enter topic and keywords of your blog post",
        description: `Optionally, you can also input your competitors' URLs. Our AI will analyze and comprehend the factors contributing to their higher rankings, then generate content with a superior SEO ranking.`,
        imageUrl: instagramBioImage1,
        imageAlt: "Enter topic and keywords of blog post",
      },
      {
        stepNumber: "02",
        type: "image",
        headline: "Customize Tone of Voice and Content Output",
        description: `Enter your desired tone of voice and any additional instructions regarding how you want your content to be written. Our AI will generate content that suits your brand's voice and style. Furthermore, select a word count for your article. Our AI is capable of generating content up to 6000 words in one go.`,
        imageUrl: instagramBioImage2,
        imageAlt: `Customize Tone of Voice and Content Output`,
      },
      {
        type: "image",
        stepNumber: "03",
        headline: "Generate The Final Blog Post",
        description: `Click the "Generate" button, and our AI will write a long-form blog post that is truly SEO-optimized and plagiarism-free for your chosen blog topic.`,
        imageUrl: instagramBioImage3,
        imageAlt: `Click the "Generate" button to have AI write a long-form blog post that is truly SEO-optimized and plagiarism-free.`,
      },
    ],
    finalStep: {
      stepNumber: "04",
      headline: "Edit, review, and publish",
      description: `Use our AI Content Editor to revise paragraphs, paraphrase text, enhance depth, and further increase the word count of your article.`,
    },
  };
};

export const useFeatureList = () => {
  return [
    {
      type: "image",
      title: "AI Co-Writer",
      imageUrl: InteractiveCommand,
      url: serverRoutes.editor,
      icon: HistoryEduOutlinedIcon,
      description:
        "Let our AI speed up your copywriting, receive creative and SEO-friendly headlines, outlines and topics all in one tab!",
    },
    {
      title: "File Data Analysis",
      description:
        "We offer superior file analysis for you to chat with any files, getting the most out of your data in seconds.",
      type: "video",
      videoUrl: "/videos/ai-chat/chat-with-files.mp4",
      url: serverRoutes.filechat,
      icon: ArticleOutlinedIcon,
    },
    {
      type: "image",
      imageUrl: InfoBaseHighLight,
      title: "Personalized AI with brand voice",
      url: serverRoutes.styleMatch,
      icon: VoiceChatIcon,
      description:
        "Our AI can be trained to mimic your writing styles and memorize you or your company info. Ensure your articles remain consistent with your brand voice throughout.",
    },
    {
      type: "image",
      title: "Powerful paraphrasing tools",
      imageUrl: PrebuiltToolbar,
      url: serverRoutes.editor,
      icon: AutoAwesomeOutlinedIcon,
      description:
        "Rephrase, lengthen, shorten or summarize any text while consistently maintaining the vocabulary, tone, and style appropriate for every circumstance.",
    },
    {
      type: "image",
      title: "Citation Generator",
      imageUrl: Video,
      url: serverRoutes.citation,
      icon: FormatQuoteOutlinedIcon,
      description:
        "Generate citations for webpages, books, videos, and journal articles in seconds.",
    },
    {
      type: "video",
      url: serverRoutes.aiDetector,
      icon: ContentPasteSearchOutlinedIcon,
      title: "AI content detector",
      videoUrl: "/videos/generator/ai-detector.mp4",
      description:
        "Assess the proportion of your content that is perceived as being created by a human with our built-in AI content detector.",
    },
    /*
        {
          title: "Powerful AI Content Editor",
          description:
            "Generate long form article within one click that Google will love! Powered built-in AI Image generator, Paraphraser, Co-Writer, Summarizer, Citation Generator and more to ensure that every word is precisely crafted to perfection.",
          type: "image",
          imageUrl: InteractiveCommand,
        },
        */
  ];
};

export const useMainFeatureList = () => {
  return [
    {
      value: "blogs",
      headline: "Write Authentic Blog Posts 10x Faster",
      subheadline: "Generative AI, doesn't have to be generic.",
      content: [
        {
          icon: SourceOutlinedIcon,
          title: "",
          description: `Create original and plagiarism-free long-form content that's ${withHtmlLink(
            "always true to you",
            serverRoutes.styleMatch
          )}.`,
        },
        {
          icon: ContentPasteOutlinedIcon,
          title: "Ensure the authenticity of your article",
          description:
            "Balance SEO optimization with human-friendly content to avoid potential penalties from search engine.",
        },
        {
          icon: GradingOutlinedIcon,
          title: "",
          description: `Express Your Unique Voice with AI, let AI help you shape content that truly reflects your ideas and personality.`,
        },
      ],
      description: `
          <p>✔ Overcome writer's block and kickstart your articles effortlessly with the help of AI.</p>
          <p>✔ Create original and plagiarism-free long-form content quickly in your own words</p>
          <strong>✔ Repurpose your existing content </strong>
          <p>✔ Improve your existing content with AI that lives in other platforms such as Contentful, Sanity, Google Docs, and WordPress etc</p>
          <p>✔ Maximize your productivity and create captivating content quickly with our rich text content editor, the AI-powered solution that outperforms the competition.</p>
          `,
      imageUrl: generativeAiPic,
      imageAlt:
        "Showcase an article on using AI on increasing productivity written using Junia's article editor",
      additionalList: [],
    },
    {
      value: "advertising",
      headline: "Unlock Your Blogging Potential",
      content: [
        {
          icon: ContentPasteOutlinedIcon,
          title: "",
          description:
            "Get access to a wide range of AI writing tools. Produce more content in less time",
        },
        {
          icon: ViewInArOutlinedIcon,
          title: "",
          description: `Access to a wide range of AI writing tools, styles, and formats to create visually appealing and engaging articles`,
        },
        {
          icon: CreditScoreOutlinedIcon,
          title: "",
          description:
            "Reduce costs associated with hiring designers and copywriters",
        },
      ],
      description: `<p>✔ Create persuasive ad copy quickly and easily</p>
        <p>✔ Generate multiple ad variations quickly and easily</p>
        <p>✔ Access to a wide range of templates, styles, and formats to create visually appealing and engaging ads</p>
        <p>✔ Reduce costs associated with hiring designers and copywriters</p>`,
      imageUrl: campaignPic,
      imageAlt:
        "Showcase of an article on how to write a influencer marketing campaign using Junia AI",
      additionalList: [],
    },
    {
      value: "emails",
      headline: "Write persuasive emails in seconds.",
      content: [
        {
          icon: EmailOutlinedIcon,
          title: "",
          description: "Generate high-quality email subject lines in seconds",
        },
        {
          icon: ManageAccountsOutlinedIcon,
          title: "",
          description: `Write personalized ${withHtmlLink(
            "sales emails",
            templateServerRouteBySlug("cold-email-email")
          )} that converts.`,
        },
        {
          icon: QuickreplyOutlinedIcon,
          title: "",
          description: `Quickly respond to messages, comments, ${withHtmlLink(
            "reviews",
            templateServerRouteBySlug("review-responder")
          )}, and support tickets with a friendly tone.`,
        },
      ],
      description: ``,
      imageUrl: askforRaisePic,
      imageAlt: "Showcase of an email on how to ask for a raise using Junia AI",
      additionalList: [],
    },

    {
      value: "socialmedia",
      subheadline:
        "Never suffer from writer's block again - Junia helps you spark ideas and create content effortlessly.",
      headline: "Create Social Posts in seconds",
      content: [
        {
          icon: ScheduleOutlinedIcon,
          title: "Faster content production",
          description:
            "Speed up your writing process and create 10x more social media posts in a fraction of the time.",
        },
        {
          icon: EqualizerOutlinedIcon,
          title: "Grow your following",
          description: `Create attention-grabbing ${withHtmlLink(
            "social media posts",
            templateServerRouteBySlug("facebook-post")
          )} that drive engagement and growth.`,
        },
        {
          icon: ChromeReaderModeOutlinedIcon,
          title: "Tools and templates",
          description:
            "Simplify content creation with over 50+ customizable tools and templates.",
        },
      ],
      description:
        "<p>Do you find it challenging to come up with new ideas for your social media posts every day?</p> <p>Never suffer from writer's block again - Junia helps you spark ideas and create content effortlessly.</p>",
      imageUrl: launchProductPic,
      imageAlt:
        "A social media post on launching a product using Junia AI Chat",
      additionalList: [],
    },
  ] as const;
};
