import React from "react";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import { headerHeight } from "modules/non-protected-page/shared";
import {
  Container,
  ResponsiveText,
} from "modules/marketing-pages/front-page/shared";
import HowItWorks from "modules/marketing-pages/front-page/how-it-works";
import ReadyToLevelUp from "modules/marketing-pages/ready-to-level-up";
import "react-multi-carousel/lib/styles.css";
import { serverRoutes } from "client-server-shared/config/routes";

import { IndexPageHeadTags } from "common/head-tags";
import { HighlightedCallToAction } from "modules/marketing-pages/call-to-action";
import Image from "next/image";
import DescriptionIcon from "@mui/icons-material/Description";

import JuniaBlog from "public/materials/junia-blog.png";
import JuniaBlogMobile from "public/materials/junia-blog-mobile.png";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContentTypes from "public/materials/content-types.png";
import { Button } from "components/ui/button";
import { Avatar } from "components/ui/avatar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import { NextLink } from "components/next-link";
import Testmonial from "modules/marketing-pages/front-page/testmonial";
import Amazing from "modules/marketing-pages/front-page/amazing";
import Script from "next/script";

import { HighlightedSpan, InlineSpan } from "components/as-inline-span";
import { Features } from "modules/marketing-pages/front-page/features";
import Faq, {
  useFaqList,
} from "modules/marketing-pages/front-page/front-page-faq";
import { buildJsonLd } from "modules/marketing-pages/faq-to-json-ld";
import { ReviewMarkup } from "modules/marketing-pages/testmonial-list";
import { WriterList } from "modules/auth/image-list";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Stars } from "modules/marketing-pages/front-page/stars";
import writeLogo from "public/icons/write-logo.png";

const HumanCard = ({
  image,
  alt,
  text,
  extraText,
}: {
  image: string;
  text: string;
  alt: string;
}) => {
  return (
    <Box
      component="figure"
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Image src={image} alt={alt} height={60} width={60} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "16px 24px",
          borderRadius: "15px",
          position: "relative",
          opacity: 0.8,
          marginRight: "24px",
          "&:before": {
            content: "''",
            position: "absolute",
            width: 0,
            height: 0,
            borderWidth: "10px",
            top: "5px",
            right: "-5px",
            border: "21px solid transparent",
            borderBottomColor: "#FFFFFF",
            borderRight: "0px solid",
            borderTop: 0,
            marginTop: "-17px",
            transform: "rotate(24deg)",
          },
        }}
      >
        <Typography color="#000000" component="figcaption">
          {text}
        </Typography>
      </Box>
      {extraText && (
        <Box
          sx={{
            position: "relative",
            marginRight: "24px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              padding: "16px 24px",
              position: "relative",
              display: "flex",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              opacity: 0.8,

              alignItems: "center",
              justifyContent: "space-between",
              "&:before": {
                content: "''",
                position: "absolute",
                width: 0,
                height: 0,
                borderWidth: "10px",
                top: "5px",
                right: "-5px",

                border: "21px solid transparent",
                borderBottomColor: "#FFFFFF",
                borderRight: "0px solid",
                borderTop: 0,
                marginTop: "-17px",
                transform: "rotate(24deg)",
              },
            }}
          >
            <Typography color="#000000" component="figcaption">
              {extraText}
            </Typography>
            <DescriptionIcon
              sx={{
                marginLeft: "12px",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const HumanCardLists = () => {
  return null;
};

const PurpleLinePointer1 = ({ sx = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      viewBox="0 0 1516 1887"
      fill="none"
      style={sx}
    >
      <path
        d="M1515.5 17.0045L0 17.0053"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <line
        x1="548.706"
        y1="1816.12"
        x2="548.706"
        y2="23.1172"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <circle cx="549" cy="1832" r="55" fill="#BF32D6" />
    </svg>
  );
};

const SecondSection1 = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-180px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {"AI writer"}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {"Generate comprehensive people-first content in minutes"}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 2,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection2 = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-100px",
        left: "30%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {"AI images"}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          Auto-generate irresistible, high CTR images for your blog
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection3 = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-170px",
        right: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {"Auto SEO Research"}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {
            "Create outlines based on what's working for your competitors and optimize your content for search engines"
          }
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection4 = () => {
  return (
    <Box
      sx={{
        background: "rgba(15,23,42,.9)",
        position: "absolute",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "15px",
        top: "50%",
        zIndex: 3,
        left: "-100px",
        columnGap: "16px",
      }}
    >
      <Image
        src={writeLogo}
        height={50}
        width={55}
        alt={
          "A pencil on top of a piece of paper, full of text, symbolizing writing long-form content."
        }
      ></Image>
      <Typography
        sx={{
          maxWidth: "200px",
          textAlign: "left",
        }}
      >
        <Typography
          fontWeight={600}
          component="strong"
          sx={{
            color: "text.white",
            fontSize: "16px",
          }}
        >
          {"Write 3000+ word long form content"}
        </Typography>
      </Typography>
    </Box>
  );
};

const SecondSection = () => {
  return (
    <Box
      component="section"
      sx={{
        padding: "70px 0",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              width: "100%",
              "& > img": {
                height: "100%",
                width: "100%",
              },
            }}
          >
            <Image
              alt="Screenshot of the Junia.ai content creation software's user interface, highlighting its capabilities for fast and efficient content generation. The screen features an AI writer and SEO score panel with an 85% rating in the top 2% tier, signifying effective search engine optimization. Notable features include producing 99% human-like content that is plagiarism-free, keyword analysis, and tools for drafting outlines and generating blog posts. A central image of a cup emanating a glowing, fiery effect symbolizes the creative process of content generation, set against a minimalist background with an 'AI is generating...' message below."
              src={JuniaBlog}
              placeholder="blur"
              height={700}
              width={1000}
            />
          </Box>
          <SecondSection1 />
          <SecondSection2 />
          <SecondSection3 />
          <SecondSection4 />
        </Box>
        <Box
          component={Image}
          src={JuniaBlogMobile}
          placeholder="blur"
          height={700}
          width={700}
          alt="Screenshot of an AI content creation tool interface featuring AI writer, AI images, and Auto SEO Research. The UI showcases features such as generating comprehensive people-first content quickly, crafting compelling, high click-through rate images for blogs and optimizing content for search engines with an 85% SEO score gauge. It showcases a vibrant graphic of a cup with a dynamic, glowing content-creation effect. Key attributes include a top 2% SEO score indicator, assurances of 99% human-like and plagiarism-free content, functionalities for keyword analysis, and tools for creating outlines and writing long-form content based on competitive ranking keywords."
          sx={{
            width: "100%",
            height: "100%",
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
            },
          }}
        />
      </Container>
    </Box>
  );
};

const OverlapIcon = ({ icon, jsxIcon, noIconBg, sx = {}, reverse, index }) => {
  const Icon = icon;

  if (reverse) {
    return (
      <Box
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <Avatar
          sx={{
            width: "45px",
            height: "45px",

            background: "#CF24BE",
            ...(noIconBg ? { background: "transparent" } : {}),
          }}
        >
          <Box />
        </Avatar>
        <Avatar
          sx={{
            width: "45px",
            height: "45px",
            background: "transparent",
            bottom: "-15px",
            left: "-12px",
            position: "absolute",
            "&:after": {
              content: "''",
              background: "#F5F5F5",

              opacity: 0.4,
              position: "absolute",
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Icon
            sx={{
              zIndex: 2,
              width: "17px",
              height: "17px",
              fill: "white",
            }}
          />
        </Avatar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Avatar
        sx={{
          width: "45px",
          height: "45px",
          position: "relative",
          background: "#CF24BE",
          ...(noIconBg ? { background: "transparent" } : {}),
        }}
      >
        {jsxIcon || index}
      </Avatar>
    </Box>
  );
};

const ThirdSectionSection = ({
  jsxIcon,
  icon,
  title,
  description,
  isLast,
  index,
  noIconBg,
  titleProps = {},
}) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        columnGap: "48px",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <OverlapIcon
          noIconBg={noIconBg}
          jsxIcon={jsxIcon}
          icon={icon}
          index={index}
        />
        <Box
          sx={{
            height: "50px",
            width: "1px",
            borderLeft: isLast ? 0 : "1px dashed #b59fd0",
            marginTop: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          marginTop: "-6px",
        }}
      >
        <ResponsiveText
          color="text.white"
          fontSize={24}
          decrement="medium"
          {...titleProps}
          sx={{
            fontWeight: 700,
            ...(titleProps.sx || {}),
          }}
        >
          {title}
        </ResponsiveText>
        <ResponsiveText
          fontSize={18}
          decrement="medium"
          sx={{
            maxWidth: "400px",
          }}
          fontWeight={500}
          color="#BCBCBC"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const ThirdSection = () => {
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            },
            padding: "0 12px",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "column-reverse",
              lg: "row-reverse",
            },
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              icon={CreateOutlinedIcon}
              index={1}
              title={"One-Shot Long Article Creation"}
              description={
                "With just one click, our AI tackles keyword research, long-form content, images and SEO meta data."
              }
            />
            <ThirdSectionSection
              index={2}
              icon={AttachMoneyOutlinedIcon}
              title={"End The Backlink Struggle"}
              description={
                "Make up for lack of backlinks with top-notch, AI-written content."
              }
            />
            <ThirdSectionSection
              index={3}
              icon={QueryBuilderOutlinedIcon}
              title={"Profit from Your Traffic"}
              isLast
              description={
                "Increase conversion rates, attract quality traffic consistently with higher SEO ranking."
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              marginBottom: {
                xs: "70px",
                sm: "70px",
                md: "70px",
                lg: "0",
              },
            }}
          >
            <ResponsiveText
              color="text.white"
              variant="h2"
              fontSize={55}
              decrement="large"
              sx={{
                maxWidth: "500px",
              }}
            >
              {"Rank Higher with Long-form Content"}
            </ResponsiveText>
            <ResponsiveText
              fontSize={20}
              decrement="medium"
              sx={{
                maxWidth: "400px",
              }}
              color="#BCBCBC"
            >
              <InlineSpan inherit>
                Drive more traffic to your website with truly quality content,
                starting from{" "}
              </InlineSpan>
              <HighlightedSpan
                inherit
                sx={{
                  fontWeight: 700,
                }}
              >
                this week
              </HighlightedSpan>
              <InlineSpan inherit>.</InlineSpan>
            </ResponsiveText>

            <HighlightedCallToAction
              rootProps={{
                sx: {
                  maxWidth: "390px",
                  margin: "12px auto 0 0",
                },
              }}
            >
              {"Get More Traffic With Long-Form Content"}
            </HighlightedCallToAction>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const FourthSectionSection = ({ icon, to, title, description }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        width: "100%",
      }}
    >
      <Button
        component={NextLink}
        capitalize
        target="__blank"
        href={to}
        variant="contained"
        sx={{
          display: "flex",
          textDecoration: "none",
          width: {
            xs: "100%",
            sm: "100%",
            md: "500px",
            lg: "500px",
          },
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          borderRadius: "15px",
          padding: "24px",
          margin: "auto",
          "&:hover": {
            background: "white",
            opacity: 0.8,
          },
          "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <Avatar
            sx={{
              background: "rgba(54, 15, 103, 0.25)",
            }}
          >
            <Icon
              sx={{
                fill: "#360F67",
              }}
            />
          </Avatar>
          <Box>
            <Typography variant="body2" color="#747474">
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
              }}
              fontWeight={600}
              variant="body1"
              color="#000000"
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <ChevronRightOutlinedIcon
          sx={{
            fill: "initial",
          }}
        />
      </Button>
    </Box>
  );
};

const FithSection = () => {
  return (
    <Box
      component="section"
      sx={{
        padding: "124px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            padding: {
              xs: "0 24px",
              sm: "0 24px",
              md: "0 0 0 20%",
              lg: "0 0 0 20%",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <ResponsiveText
              sx={{
                zIndex: 1,
                position: "relative",
                maxWidth: "600px",
              }}
              fontSize={50}
              decrement="medium"
              color="text.white"
              variant="h3"
            >
              {"More than 50+ AI Writing Templates to choose from"}
            </ResponsiveText>
            <Box
              sx={{
                position: "absolute",
                background:
                  "linear-gradient(180deg, rgba(24, 75, 255, 0) 0%, rgba(23, 74, 255, 0.6) 100%)",
                height: "200px",
                width: "200px",
                filter: "blur(42px)",
                opacity: 0.6,
                top: 0,
                transform: "rotate(44.76deg)",
              }}
            />
          </Box>
          <ResponsiveText
            sx={{
              zIndex: 1,
              maxWidth: "400px",
            }}
            fontSize={22}
            decrement="medium"
            color="text.white"
          >
            {
              "Streamline brainstorming, outlining, drafting, and editing for blogs and more."
            }
          </ResponsiveText>
          <Box>
            <Button
              variant="contained"
              capitalize
              component="a"
              target="__blank"
              href={serverRoutes.templates}
              sx={{
                backgroundColor: "#604088",
                color: "white",
                borderRadius: "10px",
                padding: "12px 24px",
                fontSize: "18px",
                "&:hover": {
                  backgroundColor: "#604088",
                  opacity: 0.8,
                },
              }}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              <ResponsiveText fontSize={18} decrement="medium">
                {"See Templates"}
              </ResponsiveText>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "initial",
            },
          }}
        >
          <Image
            src={ContentTypes}
            alt={
              "Four squares with the words blog post, product description, and resume written on them."
            }
            height={500}
            width={500}
            style={{
              width: "100%",
              height: "100%",
            }}
          ></Image>
        </Box>
      </Box>
    </Box>
  );
};

const SithSectionSection = ({ text, icon }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "12px",
      }}
    >
      <Icon
        sx={{
          height: "60px",
          width: "60px",
          fill: "#FFC107",
        }}
      />
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
        }}
        color={"#5DADE2"}
      >
        {text}
      </Typography>
    </Box>
  );
};

const JuniaChatSectionSection = ({ text, description, icon }) => {
  return (
    <Box component="li">
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          rowGap: "24px",
        }}
      >
        <OverlapIcon reverse icon={icon || CreateOutlinedIcon} sx={{}} />
        <ResponsiveText
          fontSize={20}
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
          {text}
        </ResponsiveText>
        <ResponsiveText
          fontSize={16}
          sx={{
            maxWidth: "400px",
          }}
          color="#454545"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const Hero = React.memo(() => {
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
        position: "relative",
        padding: "30px 0 0 0",
        minHeight: {
          xs: "initial",
          sm: "initial",
          md: "initial",
          xl: `calc(100vh - ${headerHeight}px)`,
        },
        padding: {
          xs: "30px 0 0 0",
          sm: "30px 0 0 0",
          md: "30px 0 0 0",
          lg: "30px 0 180px 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: "24px",
        }}
      >
        <Stars />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: {
            xs: `24px`,
            sm: `24px`,
            md: `calc((100vh - ${headerHeight}px) / 10)`,
            xl: `calc((100vh - ${headerHeight}px) / 10)`,
          },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{
            fontSize: {
              xs: "2.4rem",
              sm: `2.5rem`,
              md: `3rem`,
              xl: `3.5rem`,
              lg: "4rem",
            },
            "& > span": {
              fontSize: {
                xs: "2.4rem",
                sm: `2.5rem`,
                md: `3rem`,
                xl: `3.5rem`,
                lg: "4rem",
              },
            },
            color: "text.white",
            textAlign: "center",
            margin: "auto",
            maxWidth: "850px",
            lineHeight: 1.2,
            width: {
              xs: "100%",
              sm: `100%`,
              md: `100%`,
              xl: `100%`,
              lg: "100%",
            },
          }}
        >
          Generate High Ranking Content and Drive Traffic on Autopilot
        </Typography>

        <ResponsiveText
          color="#BCBCBC"
          variant="body2"
          fontSize={22}
          textAlign={"center"}
          sx={{
            margin: "24px auto 0 auto",
            maxWidth: "800px",
            fontWeight: 400,
          }}
        >
          {/*
              Elevate your site's SEO with Junia AI. Drive more organic traffic, improve visibility, and climb rankings with quality, SEO-optimized content.
              */}
          <InlineSpan inherit>
            Elevate your site's SEO with Junia AI. Drive more{" "}
          </InlineSpan>
          <InlineSpan
            inherit
            component="strong"
            sx={{
              fontWeight: 700,
            }}
          >
            organic traffic
          </InlineSpan>
          <InlineSpan inherit>, improve visibility, and </InlineSpan>
          <InlineSpan
            inherit
            component="strong"
            sx={{
              fontWeight: 700,
            }}
          >
            climb rankings
          </InlineSpan>
          <InlineSpan inherit> with quality, SEO-optimized, and </InlineSpan>
          <InlineSpan
            inherit
            component="strong"
            sx={{
              fontWeight: 700,
            }}
          >
            ready-to-rank
          </InlineSpan>
          <InlineSpan inherit> content.</InlineSpan>
        </ResponsiveText>
        {/*
         <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            alignItems: "flex-end",
            columnGap: "8px",
          }}
        >
          <WriterList showAltText />
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: "text.white",
            }}
          >
            {t("68,362+ blog posts written.")}
          </ResponsiveText>
        </Box>
        */}
        <HighlightedCallToAction
          rootProps={{
            sx: {
              margin: "48px auto 48px auto",
              maxWidth: "400px",
              padding: "12px 6px 12px 12px",
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            },
          }}
        >
          {"Get Your First High-Ranking Blog Post"}
        </HighlightedCallToAction>
        <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            columnGap: "8px",
          }}
        >
          <WriterList showAltText />
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: "text.white",
            }}
          >
            {"2,000,000+ articles generated."}
          </ResponsiveText>
        </Box>
      </Container>
    </Box>
  );
});

const MainHero = React.memo(() => {
  return <Hero />;
});

const ScriptRenderer = React.memo(() => {
  const faqLists = useFaqList();
  return (
    <>
      <ReviewMarkup />
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        id="junia-ai-faq"
        key="junia-ai-faq"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildJsonLd(faqLists)),
        }}
      />
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            name: "Keyword Research For Blog Posts Generation with Junia AI: Matching Customer Search Intents",
            description:
              "Video demonstrating how users employ Junia AI's advanced features to conduct detailed keyword research for their blog posts. The aim is to better match the search intents of potential customers, thereby increasing site traffic and engagement.",
            thumbnailUrl: "https://www.junia.ai/chat-1-thumbnail.png",
            uploadDate: "2023-09-10",
            contentUrl: "https://www.junia.ai/chat1.mp4",
            embedUrl: "https://www.junia.ai/chat1.mp4",
            duration: "PT12S",
          }),
        }}
      />
    </>
  );
});

const Struggle = React.memo(() => {
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <ResponsiveText
          color="text.white"
          variant="h2"
          fontSize={55}
          sx={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "auto",
          }}
          decrement="large"
        >
          {"Struggling to Get Traffic to Your Blog?"}
        </ResponsiveText>
        <ResponsiveText
          fontSize={20}
          sx={{
            maxWidth: "700px",
            margin: " 12px auto 0 auto",
            textAlign: "center",
          }}
          decrement="medium"
          color="#BCBCBC"
        >
          {
            "You work hard on your blog, but search engines aren't showing it. You're not alone - we've been there too."
          }
        </ResponsiveText>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "72px",
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={"You invest in other AI writing tools"}
              description={
                <InlineSpan inherit>
                  <InlineSpan inherit>You trust them to deliver</InlineSpan>{" "}
                  <InlineSpan inherit component="s">
                    SEO-optimized
                  </InlineSpan>{" "}
                  <InlineSpan inherit> blog posts.</InlineSpan>
                </InlineSpan>
              }
            />
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={"You publish your first post"}
              description={"Expecting a flood of traffic and engagement."}
            />
            <ThirdSectionSection
              noIconBg
              jsxIcon={
                <CloseOutlinedIcon
                  sx={{
                    fill: "#FF0000",
                    height: "50px",
                    width: "50px",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 800,
                },
              }}
              title={"You get 0 traffic to your blog"}
              isLast
              description={
                "Despite your efforts, search engines just don't notice. Money and time wasted."
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
          }}
        >
          <Button
            capitalize
            variant="contained"
            component={NextLink}
            href={serverRoutes.register}
            sx={{
              marginTop: "12px",
              borderRadius: "22px",
              padding: "12px 36px",
              background: "#CF24BE",
              fontSize: "20px",
              fontWeight: 500,
              "&:hover": {
                background: "#CF24BE",
                opacity: 0.8,
              },
            }}
          >
            <ResponsiveText fontSize={20} decrement="medium">
              {"End SEO Struggles"}
            </ResponsiveText>
          </Button>
        </Box>
      </Container>
    </Box>
  );
});

export default function IndexPage({ seoBot }: { seoBot: boolean }) {
  return (
    <>
      <IndexPageHeadTags />
      <ScriptRenderer />

      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <MainHero />
        <SecondSection />
        <ThirdSection />
        <Testmonial />
        <Struggle />
        <Features />
        <Amazing />
        <FithSection />
      </Box>
      {/*
        <MoreFeatureList />
        */}
      {/*
        <JuniaChatSection />
      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <JuniaArtSection />
        <SithSection />
      </Box>

    */}
      <Box
        sx={{
          background: "#111827",
        }}
      >
        <HowItWorks
          sx={{
            background: "inherit",
          }}
        />
      </Box>

      <ReadyToLevelUp
        title={"Ready To Win the SEO Game?"}
        titleProps={{
          sx: {
            maxWidth: "800px",
            margin: "auto auto 12px auto",
            lineHeight: 1.2,
          },
        }}
        callToAction={"Boost Your SEO with AI"}
        description={
          "Say goodbye to endless writing with no SEO gain. With the #1 AI Writer for SEO, write faster and get more clicks on your blogs."
        }
      />
      <Faq />
      {seoBot && (
        <div>
          <a href="https://climate.stripe.com/7doPyW">Junia Stripe climate</a>
          <a href="https://apps.shopify.com/junia-ai">Junia Shopify</a>
          <a href="https://www.amazon.com/Todoer-best-todo-ever-need/dp/B0852QZG88/">
            todo
          </a>
          <a href="https://www.adgully.com/how-to-leverage-ai-to-enhance-your-digital-marketing-posts-143524.html">
            ad
          </a>
          <a href="https://www.tribuneindia.com/news/impact-feature/best-5-ai-article-writers-569000/">
            India
          </a>
          <a href="https://markets.businessinsider.com/news/stocks/junia-ai-recognized-as-one-of-the-fastest-growing-ai-writing-platforms-in-2024-1032927542">
            Insider
          </a>
        </div>
      )}
      {/*
        <ContentCreation />
      <AiArt />
      <JuniaChat />
      <ImageRestorer />
      <AboutJunia />
      <PricingSection />
      <Faq />
  */}
    </>
  );
}

/*
export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const userAgent = context.req.headers["user-agent"];
  let seoBot = false;
  if (
    userAgent &&
    (userAgent.toLowerCase().includes("ahrefsbot") ||
      userAgent.toLowerCase().includes("semrushbot"))
  ) {
    seoBot = true;
  }
  return {
    props: {
      seoBot,
    },
  };
};
*/
